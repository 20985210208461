<template>
  <div>
    <v-btn color="success" @click.stop="openNewRequestForm">
      <v-icon>
        mdi-currency-usd
      </v-icon>

      <span class="font-weight-bold">
        {{ t("new_request") }}
      </span>
    </v-btn>

    <v-btn v-if="mainPurse && hasEnoughForWithdrawal" color="success" @click.stop="openQuickRequestForm" class="ml-2" outlined>
      <span class="font-weight-bold">
        {{ t("quick_request") }}
      </span>
    </v-btn>

    <v-dialog v-model="newRequestDialog" width="700" persistent>
      <v-card>
        <v-card-title class="headline lighten-2">
          {{ t("request_payout") }}
        </v-card-title>

        <v-card-text class="pt-4">
          <ca-alert v-if="errorMessages.default" type="error">{{ errorMessages.default }}</ca-alert>

          <v-form ref="request-payout-form">
            <v-row align="center">
              <v-col cols="12" sm="12">
                <v-select
                  v-model="purse"
                  :items="userPursesOptions"
                  item-text="no"
                  item-value="id"
                  :label="t('payout_system')"
                  persistent-hint
                  return-object
                  :error-messages="errorMessages.purseId ? [errorMessages.purseId] : []"
                  :rules="purseFieldRules"
                />
                <div v-if="selectedPurseType" class="text-caption">
                  {{ t("Fee") }}: {{ selectedPurseType.fee }} {{ t("Min_payout_is") }}:
                  {{ selectedPurseType.min_withdraw_formatted }}
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="10" sm="10">
                <v-text-field
                  v-model="requestAmount"
                  type="number"
                  :error-messages="errorMessages.amount ? [errorMessages.amount] : []"
                  :label="t('request_payout_amount_field_label') + ' ($)'"
                  :rules="amountFieldRules"
                />
              </v-col>

              <v-col cols="2" sm="2">
                <v-btn block  :loading="sendingPayoutRequest" @click="useAllAvailableAmount">
                  {{ $t('common.max') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" :loading="sendingPayoutRequest" @click="sendRequest">
            {{ t("send_request") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="quickRequestDialog" width="700" persistent>
      <v-card>
        <v-card-title class="headline lighten-2">
          {{ t("request_payout") }}
        </v-card-title>

        <v-card-text class="pt-4">
          <ca-alert v-if="errorMessages.default" type="error">{{ errorMessages.default }}</ca-alert>

          <div v-if="mainPurseType">
            {{ t('quick_request_text', {amount: availableWithdrawal, wallet: mainPurseType.name, number: mainPurse.no})}}
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="quickRequestDialog = false">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" :loading="sendingPayoutRequest" @click="sendQuickRequest">
            {{ t("send_request") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CaAlert from "@/views/components/Alert";

export default {
  components: { CaAlert },
  data() {
    return {
      purse: null,
      newRequestDialog: false,
      quickRequestDialog: false,
      errors: [],
      requestAmount: null,
      errorMessages: {
        amount: null,
        default: null,
        purseId: null
      },
      sendingPayoutRequest: false
    };
  },
  computed: {
    availableWithdrawal() {
      return this.$store.state.payouts.availableWithdrawal;
    },
    selectedPurse() {
      return this.purse ? this.userPurses.filter(x => x.id === this.purse.id)[0] : null;
    },
    selectedPurseType() {
      return this.selectedPurse ? this.purseTypes.filter(x => x.id === this.selectedPurse.type)[0] : null;
    },
    userPurses() {
      return this.$store.state.payouts.purses;
    },
    userPursesOptions() {
      return this.userPurses
        .filter(purse => purse.active)
        .map(purse => {
          const purseTypeName = this.purseTypes.filter(x => x.id === purse.type)[0].name;

          return {
            no: this.t("payout_system_field_name", { purseType: purseTypeName }) + ": " + purse.no,
            id: purse.id
          };
        });
    },
    mainPurse() {
      return this.userPurses.length > 0 ? this.userPurses.filter(x => x.main === 1)[0] : null;
    },
    mainPurseType() {
      return this.mainPurse ? this.purseTypes.filter(x => x.id === this.mainPurse.type)[0] : null;
    },
    purseTypes() {
      return this.$store.state.payouts.pursesTypes;
    },
    purseFieldRules() {
      const fieldLabel = this.t("payout_system");

      return [v => !!v || this.$t("common.form.errors.required", { field: fieldLabel })];
    },
    amountFieldRules() {
      const fieldLabel = this.t("request_payout_amount_field_label");

      return [
        v => !!v || this.$t("common.form.errors.required", { field: fieldLabel }),
        v => !isNaN(v) || this.$t("common.form.errors.number", { field: fieldLabel }),
        v => v > 0 || this.$t("common.form.errors.number", { field: fieldLabel })
      ];
    },
    hasEnoughForWithdrawal() {
      return this.mainPurse && this.mainPurseType && this.availableWithdrawal >= this.mainPurseType.min_withdraw
    }
  },
  mounted() {
    this.setMainPurseAsSelected();
  },
  methods: {
    t(key, params) {
      return this.$t("pages.payouts." + key, params);
    },
    sendRequest() {
      if (this.sendingPayoutRequest) {
        return;
      }

      if (!this.$refs["request-payout-form"].validate()) {
        return;
      }

      this.sendingPayoutRequest = true;

      this.errorMessages = {
        amount: null,
        default: null,
        purseId: null
      };

      this.$store
        .dispatch("payouts/createPayoutRequest", {
          amount: this.requestAmount,
          purseId: this.purse.id
        })
        .then(() => {
          this.requestAmount = null;
          this.sendingPayoutRequest = false;

          this.$store.dispatch("payouts/loadAvailableWithdrawal");
          this.$refs["request-payout-form"].reset();

          this.$store.commit("payouts/triggerReloadPayoutsList", true);

          this.closeDialog();
        })
        .catch(response => {
          this.sendingPayoutRequest = false;

          if (response.data.errors) {
            if (response.data.errors.default) {
              this.errorMessages.default = response.data.errors.default[0];
            }

            if (response.data.errors.amount) {
              this.errorMessages.amount = response.data.errors.amount[0];
            }

            if (response.data.errors.purseId) {
              this.errorMessages.purseId = response.data.errors.purseId[0];
            }
          }
        });
    },

    sendQuickRequest() {
      this.sendingPayoutRequest = true;

      this.$store
        .dispatch("payouts/createPayoutRequest", {
          amount: this.availableWithdrawal,
          purseId: this.mainPurse.id
        })
        .then(() => {
          this.sendingPayoutRequest = false;

          this.$store.dispatch("payouts/loadAvailableWithdrawal");
          this.$store.commit("payouts/triggerReloadPayoutsList", true);

          this.quickRequestDialog = false;
        })
        .catch(response => {
          this.sendingPayoutRequest = false;

          if (response.data.errors) {
            if (response.data.errors.default) {
              this.errorMessages.default = response.data.errors.default[0];
            }

            if (response.data.errors.amount) {
              this.errorMessages.default = response.data.errors.amount[0];
            }

            if (response.data.errors.purseId) {
              this.errorMessages.default = response.data.errors.purseId[0];
            }
          }
        });
    },

    setMainPurseAsSelected() {
      const purses = this.userPurses.filter(x => x.main);

      if (purses.length === 0) {
        return null;
      }

      this.purse = this.userPurses.filter(x => x.main)[0];
    },

    closeDialog() {
      this.newRequestDialog = false;
    },

    openNewRequestForm() {
      this.$store.dispatch("app/doSecureAction", {
        callback: () => {
          this.newRequestDialog = true;
        },
        mode: "both"
      });
    },

    openQuickRequestForm() {
      this.$store.dispatch("app/doSecureAction", {
        callback: () => {
          this.quickRequestDialog = true;
        },
        mode: "both"
      });
    },

    useAllAvailableAmount() {
      this.requestAmount = this.availableWithdrawal
    },
  }
};
</script>
