<template>
  <base-card>
    <v-card-title class="justify-space-between">
      <div class="card-title ma-0 ">
        {{ t("payouts") }}
      </div>

      <v-btn icon @click="reloadPayouts">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ t("payouts_subtitle") }}
    </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :headers="table.headers"
        :items="items"
        :options.sync="table.options"
        :server-items-length="totalPayouts"
        :items-per-page="itemsPerPage"
        :loading="loading"
        class="elevation-1"
        :footer-props="table.footerProps"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:page="onPageUpdate"
        @update:items-per-page="onUpdateItemsPerPage"
        @update:sort-desc="onUpdateSortDesc"
      >
        <template #item.payment_details="{ item }">
          <span v-if="item.purse_type === 'fd'">{{ item.purse_number }}</span>
          <span v-else>{{ getPurseTypeLabel(item.purse_type) }}: {{ item.purse_number }}</span>
        </template>

        <template #item.status="{ item }">
          <ca-status :color="statusColor(item.state)">
            {{ statusLabel(item.state) }}
          </ca-status>
        </template>

        <template #item.actions="{ item }">
          <span v-if="item.can_be_deleted" @click="openDeletePayoutDialog(item)">
            <ca-delete-action-icon />
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </base-card>
</template>

<script>
import CaStatus from "@/views/components/Status";
import CaDeleteActionIcon from "@/views/components/actions-icons/DeleteActionIcon";

export default {
  components: { CaDeleteActionIcon, CaStatus },
  data() {
    return {
      items: [],
      itemsPerPage: 0,
      loading: true,
      statusColors: {
        created: "grey",
        review: "cyan",
        process: "primary",
        accepted: "success",
        rejected: "warning",
        deleted: "danger"
      },
      totalPayouts: 0,
      currentPage: 1,
      currentItemsPerPage: 5,
      sortBy: ["created_date"],
      sortDesc: [true]
    };
  },
  computed: {
    table() {
      return {
        headers: [
          {
            text: this.t("payout_table.fields.created_date"),
            value: "created_date",
            sortable: true
          },
          {
            text: this.t("payout_table.fields.amount"),
            value: "amount",
            sortable: true
          },
          {
            text: this.t("payout_table.fields.payment_details"),
            value: "payment_details",
            sortable: true
          },
          {
            text: this.t("payout_table.fields.status"),
            value: "status",
            sortable: true
          },
          {
            text: this.t("payout_table.fields.action"),
            value: "actions",
            sortable: false
          }
        ],
        options: {},
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 100]
        }
      };
    },
    pursesTypes() {
      return this.$store.state.payouts.pursesTypes;
    },
    reloadPayoutsListTrigger() {
      return this.$store.state.payouts.reloadPayoutsListTrigger;
    }
  },
  watch: {
    reloadPayoutsListTrigger(val) {
      if (val) {
        this.loadPayouts();
        this.$store.commit("payouts/triggerReloadPayoutsList", false);
      }
    }
  },
  mounted() {
    this.loadPayouts();
  },
  methods: {
    loadPayouts() {
      this.loading = true;

      this.$store
        .dispatch("payouts/loadUserPayouts", {
          page: this.currentPage,
          per_page: this.currentItemsPerPage,
          sort: {
            key: this.sortBy.length > 0 ? this.sortBy[0] : "created_date",
            dir: this.sortDesc.length > 0 ? (this.sortDesc[0] ? "desc" : "asc") : "desc"
          }
        })
        .then(({ payouts, meta }) => {
          this.items = payouts;
          this.totalPayouts = meta.total;
          this.itemsPerPage = parseInt(meta.per_page);
          this.loading = false;
        });
    },
    openDeletePayoutDialog(payout) {
      this.$store.dispatch("app/showConfirmDialog", {
        message: this.t("request_delete_confirmation"),
        onConfirm: () => {
          this.$store.dispatch("app/doSecureAction", {
            callback: () => {
              this.deletePayout(payout);
            },
            mode: "password"
          });
        },
        title: null,
        onCancel: null
      });
    },
    deletePayout(payout) {
      this.$store
        .dispatch("payouts/deletePayout", payout.id)
        .then(() => {
          this.loadPayouts();
          this.$store.dispatch("payouts/loadAvailableWithdrawal");
        })
        .catch(() => {
          this.$store.dispatch("app/showDialog", {
            type: "error",
            title: "Error deleting payout",
            message: "Payout can not be deleted"
          });
        });
    },
    statusLabel(status) {
      return this.t("payouts_status_label." + status);
    },
    statusColor(status) {
      return this.statusColors.hasOwnProperty(status) ? this.statusColors[status] : "default";
    },
    reloadPayouts() {
      this.loadPayouts();
    },
    getPurseTypeLabel(purseTypeName) {
      const filteredPursesTypes = this.pursesTypes.filter(x => x.id === purseTypeName);

      if (filteredPursesTypes.length !== 1) {
        return purseTypeName;
      }

      return filteredPursesTypes[0].name;
    },
    onPageUpdate(page) {
      this.currentPage = page;
      this.loadPayouts();
    },
    onUpdateItemsPerPage(itemsPerPage) {
      this.currentItemsPerPage = itemsPerPage;
      this.loadPayouts();
    },
    onUpdateSortDesc() {
      this.loadPayouts();
    },
    t(key, params) {
      return this.$t("pages.payouts." + key, params);
    }
  }
};
</script>
