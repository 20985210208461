<template>
  <base-card>
    <v-card-title class="justify-space-between">
      <div class="card-title ma-0 ">
        {{ t("payout_details") }}
      </div>

      <v-btn icon @click="reloadUserPurses">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>{{ t("payout_details_subtitle", { days: purseHoldPeriod }) }}</v-card-subtitle>

    <v-card-text>
      <template>
        <v-simple-table v-if="userHasPurses" class="mb-5 elevation-1">
          <template #default>
            <thead>
              <tr>
                <th v-for="(heading, index) in tableHeadings" :key="index" class="text-left">
                  {{ heading.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purse, index) in purses" :key="'purse-' + index">
                <td class="font-weight-medium border-transparent text-no-wrap">
                  <img class="mr-2 purse-type-img" :src="purseTypeImg(purse.type)" alt="" />
                  {{ getPurseByName(purse.type).name }}
                </td>
                <td class="border-transparent text-no-wrap">
                  {{ purse.no }}
                </td>
                <td class="border-transparent">
                  {{ purse.created_at }}
                </td>
                <td class="border-transparent">
                  {{ getPurseByName(purse.type).fee }} / {{ getPurseByName(purse.type).min_withdraw_formatted }}
                </td>
                <td class="border-transparent">
                  <ca-status v-if="purse.active" icon="mdi-check" color="success">{{ t("active") }}</ca-status>
                  <ca-status v-if="!purse.active" icon="mdi-clock-time-five-outline" color="primary">
                    {{ t("on_hold", { date: purse.hold_until }) }}
                  </ca-status>
                </td>
                <td class="border-transparent">
                  <span v-if="purse.active && !purse.main" class="mr-3" @click="setPurseAsMain(purse)">
                    <ca-action-icon :hint="t('set_as_main')" color="success" icon="check-decagram-outline" />
                  </span>

                  <span v-if="purse.main === 0" @click="openDeletePurseDialog(purse)">
                    <ca-delete-action-icon :hint="t('delete_wallet')" />
                  </span>
                </td>
              </tr>

              <tr v-for="(purse, index) in pursesNotConfirmed" :key="'purse-not-confirmed' + index">
                <td class="font-weight-medium border-transparent text-no-wrap">
                  <img class="mr-2 purse-type-img" :src="purseTypeImg(purse.money_out_type)" alt="" />
                  {{ getPurseByName(purse.money_out_type).name }}
                </td>
                <td class="border-transparent text-no-wrap">
                  {{ purse.wallet }}
                </td>
                <td class="border-transparent">
                  {{ purse.wallet_change_date }}
                </td>
                <td class="border-transparent">
                  {{ getPurseByName(purse.money_out_type).fee }} /
                  {{ getPurseByName(purse.money_out_type).min_withdraw_formatted }}
                </td>
                <td class="border-transparent">
                  <ca-status icon="mdi-email-alert-outline" color="info">
                    {{ t("waiting_email_confirmation") }}
                  </ca-status>
                </td>
                <td class="border-transparent"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p v-else>{{ t("no_purses") }}</p>
      </template>

      <div class="text-right">
        <v-btn small color="primary" @click="openAddPurseDialog">
          <v-icon>mdi-plus</v-icon>

          {{ t("add_new") }}
        </v-btn>
      </div>
    </v-card-text>

    <ca-add-purse-dialog :show="addPurseDialogOpen" @onClose="addPurseDialogOpen = false" @onAdd="onPurseAdd" />
  </base-card>
</template>

<script>
import CaStatus from "@/views/components/Status";
import CaDeleteActionIcon from "@/views/components/actions-icons/DeleteActionIcon";
import CaActionIcon from "@/views/components/actions-icons/ActionIcon";
import CaAddPurseDialog from "@/views/pages/payouts/AddPurseDialog";

export default {
  components: { CaAddPurseDialog, CaActionIcon, CaDeleteActionIcon, CaStatus },
  data() {
    return {
      addPurseDialogOpen: false,
      pursesLoaded: true
    };
  },
  computed: {
    tableHeadings() {
      return [
        {
          name: "type",
          label: this.t("type")
        },
        {
          name: "details",
          label: this.t("details")
        },
        {
          name: "create_date",
          label: this.t("create_date")
        },
        {
          name: "fee_min_payout",
          label: this.t("fee_min_payout")
        },
        {
          name: "status",
          label: this.t("status")
        },
        {
          name: "action",
          label: this.t("action")
        }
      ];
    },
    purses() {
      return this.$store.state.payouts.purses;
    },
    pursesNotConfirmed() {
      return this.$store.state.payouts.pursesNotConfirmed;
    },
    pursesTypes() {
      return this.$store.state.payouts.pursesTypes;
    },
    purseHoldPeriod() {
      return this.$store.state.payouts.purseHoldPeriod;
    },
    userHasPurses() {
      return this.purses.length > 0 || this.pursesNotConfirmed.length > 0;
    }
  },
  methods: {
    getPurseByName(purseTypeName) {
      const data = this.pursesTypes.filter(x => x.id === purseTypeName);

      return data ? data[0] : purseTypeName;
    },
    purseTypeImg(purseTypeName) {
      return require("@/assets/images/payment-icons/" + purseTypeName + ".png");
    },
    reloadUserPurses() {
      this.pursesLoaded = false;

      this.$store.dispatch("payouts/loadUserPurses").then(() => {
        this.pursesLoaded = true;
      });
    },
    deletePurse(purse) {
      this.$store
        .dispatch("payouts/deletePurse", purse.id)
        .then(() => {
          this.$store.commit(
            "payouts/setPurses",
            this.purses.filter(x => x.id !== purse.id)
          );
        })
        .catch(response => {
          if (response.status === 403) {
            return;
          }

          if (response.status === 422 && response.data.errors.message) {
            this.$store.dispatch("app/showDialog", {
              type: "error",
              title: "Error",
              message: response.data.errors.message[0]
            });

            return;
          }

          alert("Failed deleting wallet");
        });
    },
    openDeletePurseDialog(purse) {
      this.$store.dispatch("app/doSecureAction", {
        callback: () => {
          this.$store.dispatch("app/showConfirmDialog", {
            title: null,
            message: this.t("are_you_sure_you_want_to_delete_purse"),
            onCancel: null,
            onConfirm: () => {
              this.deletePurse(purse);
            }
          });
        },
        mode: "password"
      });
    },
    setPurseAsMain(purse) {
      this.$store.dispatch("payouts/setPurseMain", purse.id).then(() => {
        this.reloadUserPurses();
      });
    },
    onPurseAdd() {
      this.addPurseDialogOpen = false;
      this.reloadUserPurses();
    },
    t(key, params) {
      return this.$t("pages.payouts." + key, params);
    },
    openAddPurseDialog() {
      this.$store.dispatch("app/doSecureAction", {
        callback: () => {
          this.addPurseDialogOpen = true;
        },
        mode: "password"
      });
    }
  }
};
</script>

<style scoped>
.purse-type-img {
  vertical-align: middle;
  width: 25px;
}
</style>
