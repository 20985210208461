<template>
  <v-chip label small :color="color" text-color="white">
    <v-icon v-if="icon" small left>
      {{ icon }}
    </v-icon>

    <slot />
  </v-chip>
</template>

<script>
export default {
  name: "CaStatus",
  props: {
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>
