<template>
  <v-row>
    <template v-if="dataLoaded">
      <v-col cols="12">
        <request-payout />
      </v-col>

      <v-col cols="12">
        <payouts-list />
      </v-col>

      <v-col cols="12">
        <payouts-details />
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12">
        <base-card></base-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import PayoutsDetails from "@/views/pages/payouts/PayoutsDetails";
import RequestPayout from "@/views/pages/payouts/RequestPayout";
import store from "@/store";
import PayoutsList from "@/views/pages/payouts/PayoutsList";

export default {
  components: { PayoutsList, RequestPayout, PayoutsDetails },
  metaInfo() {
    return {
      title: this.$t("titles.payouts")
    };
  },
  data() {
    return {
      dataLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      store.dispatch("changeThemeLoadingState", true);

      Promise.all([
        this.$store.dispatch("payouts/loadPayoutInfo"),
        this.$store.dispatch("payouts/loadAvailableWithdrawal"),
        this.$store.dispatch("payouts/loadUserPurses")
      ]).then(() => {
        this.dataLoaded = true;
        store.dispatch("changeThemeLoadingState", false);
      });
    }
  }
};
</script>
